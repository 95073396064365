import { RequiresPermissionDirective, UserService } from '@ami/angular/core/auth';
import { CORE_UI } from '@ami/angular/core/ui';
import { WorkstationService } from '@ami/shared/workstation';
import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { RouterModule } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OAuthService } from 'angular-oauth2-oidc';
import { AppSettings } from '../app.settings';
import { AreaClaim } from './area.claim';
import { AreasSelectionComponent } from './areas-selection/areas-selection.component';
import { LogoutComponent } from './logout/logout.component';
import { SiteClaim } from './site.claim';

@Component({
  selector: 'ami-menu',
  standalone: true,
  imports: [
    CommonModule,
    RequiresPermissionDirective,
    RouterModule,
    AreasSelectionComponent,
    LogoutComponent,
    CORE_UI
  ],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [NgbActiveModal]
})
export class MenuComponent implements OnInit {
  public user = this.userService.user;
  public showTestBanner = false;
  public selectedAreaName = '';
  public selectedAreaId = '';

  private workstationService = inject(WorkstationService);
  private modalService = inject(NgbModal);

  getAreas(): Array<AreaClaim> | undefined {
    const areas = this.getAmiClaimValue('Area') as unknown as Array<AreaClaim>;
    if (!areas || !areas.length) {
      this.logout();
      return;
    }

    areas.sort((a, b) => a.areaName.localeCompare(b.areaName));
    return areas;
  }

  getSiteInfo() {
    const site = this.getAmiClaimValue('Site');
    if (site) {
      return site as unknown as SiteClaim;
    }

    return undefined;
  }

  get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  constructor(
    private userService: UserService,
    private appSettings: AppSettings,
    private oauthService: OAuthService,
    private titleService: Title
  ) {
    this.showTestBanner = this.appSettings.showTestBanner;
  }

  ngOnInit(): void {
    let title = "Shop Floor";

    const siteInfo = this.getSiteInfo();
    if (siteInfo && siteInfo.siteId && siteInfo.siteName) {
      this.workstationService.updateSiteInfoInStorage(siteInfo);
    }

    const areas = this.getAreas();
    if (!areas || !areas.length) {
      this.logout();
      return;
    }

    const area = this.workstationService.getAreaInStorage() ?? { id: areas[0].areaId, label: areas[0].areaName };
    this.workstationService.updateAreaInStorage({ id: area.id, label: area.label });
    this.selectedAreaId = area.id;
    this.selectedAreaName = area.label;
    title += ` - ${area.label}`;

    this.titleService.setTitle(title);
  }

  logout(): void {
    this.workstationService.clearStorage();
    this.oauthService.logOut();
  }

  onOpenModal(modalContent: unknown): void {
    this.modalService.open(modalContent, { size: 'md' }).result.then(
      (result) => { },
      (dismissReason) => { });
  }

  onCancelModal() {
    this.modalService.dismissAll();
  }

  private getClaimValue(key: string) {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) return '';

    return claims[key];
  }

  private getAmiClaimValue(key: string) {
    return this.getClaimValue(`${this.appSettings.amiClaimBase}${key}`)
  }

  public onSelectArea(area: any) {
    this.selectedAreaId = area.id;
    this.selectedAreaName = area.label;
    this.workstationService.updateAreaInStorage(area);
    location.reload();
  }
}
