import { UserService } from '@ami/angular/core/auth';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class WorkstationAuthenticatedAuthGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private oauthService: OAuthService,
    private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = !!this.oauthService.getAccessToken();

    if (isAuthenticated === true) {
      return true;
    }

    this.userService.logout();
    this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
