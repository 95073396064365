import {
  APP_INITIALIZER,
  EnvironmentProviders,
  makeEnvironmentProviders
} from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { OAuthService } from 'angular-oauth2-oidc';
import { createHubConnection } from './create-hub-connection.function';
import { startHubConnectionWithRetryWithoutWait } from './start-hub-connection-with-retry.function';

export function provideSignalr(baseUrl: string): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: signalR.HubConnection,
      useFactory: (oAuthService: OAuthService) => 
        createHubConnection(`${baseUrl}/api/signalr/sales`, oAuthService),
      deps: [OAuthService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (connection: signalR.HubConnection) => 
        () => startHubConnectionWithRetryWithoutWait(connection),
      deps: [signalR.HubConnection],
      multi: true,
    },
  ]);
}
