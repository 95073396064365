import { UserService } from '@ami/angular/core/auth';
import { ToastComponent } from '@ami/angular/core/toasts';
import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'ami-authenticated-layout',
  standalone: true,
  imports: [RouterModule, MenuComponent, ToastComponent],
  templateUrl: './authenticated-layout.component.html',
  styleUrls: ['./authenticated-layout.component.scss'],
})
export class AuthenticatedLayoutComponent {
  private userService = inject(UserService);
  private router = inject(Router);
}
