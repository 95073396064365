import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CORE_UI } from '@ami/angular/core/ui';

@Component({
  selector: 'ami-logout',
  standalone: true,
  imports: [CORE_UI],
  templateUrl: './logout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent {
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();

  public dismiss(): void {
    this.cancel.emit();
  }

  logout(): void {    
    this.save.emit();
  }
}
