<div class="d-flex justify-content-center" [ngClass]="{'container': useContainer}">
  <div class="col-12 col-sm-8 mx-auto" [ngClass]="{
    'col-md-12' : 'large' === width || null === width,
    'col-md-10' : 'medium' === width,
    'col-md-6'  : 'small' === width
  }">
    <div class="flextable">
      <div class="flextable-item flextable-primary w-100">
        <ng-content select="[headers]"></ng-content>
      </div>
      <div class="flextable-item">
        <ng-content select="[buttons]"></ng-content>
      </div>
    </div>
    <ng-content select="[sections]"></ng-content>
  </div>
</div>
