import { Route } from '@angular/router';
import { WorkstationAuthenticatedAuthGuard } from './auth-guard/workstation-authenticated-auth-guard.service';
import { AuthenticatedLayoutComponent } from './authenticated-layout/authenticated-layout.component';
import { PublicLayoutComponent } from './public-layout/public-layout.component';

export const appRoutes: Route[] = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
    ],
  },
  {
    path: 'a',
    component: AuthenticatedLayoutComponent,
    canActivate: [WorkstationAuthenticatedAuthGuard],
    children: [
      {
        path: 'work-queue',
        loadChildren: () =>
          import('@ami/shop/work-queue').then((mod) => mod.routes),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
