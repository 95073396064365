import { Injectable, inject } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root',
})
export class WorkstationService {
  private localStorage = inject(LocalStorageService);
  private selectAreaLocalStorageKey = 'selected_area';
  private selectSiteLocalStorageKey = 'selected_site';

  public updateAreaInStorage(area: any) {
    this.localStorage.store(this.selectAreaLocalStorageKey, area);
  }

  public getAreaInStorage(): any {
    const storedValue = this.localStorage.retrieve(this.selectAreaLocalStorageKey) as any;
    return storedValue;
  }

  public updateSiteInfoInStorage(site: any) {
    this.localStorage.store(this.selectSiteLocalStorageKey, site);
  }

  public getSiteInfoInStorage(): any {
    const storedValue = this.localStorage.retrieve(this.selectSiteLocalStorageKey) as any;
    return storedValue;
  }

  public clearStorage() {
    this.localStorage.clear();
  }
}
