import { Injectable, signal } from '@angular/core';
import * as signalR from "@microsoft/signalr";

@Injectable({
  providedIn: 'root',
})
export class AmiHubService {
  private state = signal(signalR.HubConnectionState.Connecting);
  public status = this.state.asReadonly();

  constructor(private connection: signalR.HubConnection) {
    connection.onreconnecting(() => this.setStatus());
    connection.onreconnected(() => this.setStatus());
    connection.onclose(() => this.setStatus());

    this.state.set(connection.state);
  }

  private setStatus() {
    this.state.set(this.connection.state);
  }
}