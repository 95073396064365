import * as signalR from '@microsoft/signalr';
import { OAuthService } from 'angular-oauth2-oidc';

export function createHubConnection(url: string, oAuthService: OAuthService): signalR.HubConnection {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: () => {
        return oAuthService.getAccessToken();
      },
    })
    .withAutomaticReconnect()
    .build();

  return connection;
}

