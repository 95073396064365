import { localSessionOAuthStorageFactory } from '@ami/angular/core/auth';
import { ErrorHandling } from '@ami/angular/core/error-handling';
import { HttpInterceptorService } from '@ami/angular/core/http-interception';
import { provideHttpBaseUrlReplacement } from '@ami/angular/core/http';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import {
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { AuthConfig, OAuthStorage, provideOAuthClient } from 'angular-oauth2-oidc';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { appRoutes } from './app.routes';
import { appVersion, applicationInsightsInstrumentationKey, nxApiBaseUrl, nxIdentityIssuerBaseUrl } from './app.settings';
import { AMI_SITE_TYPE, AmiSiteType } from '@ami/angular/core/ui';
import { provideSignalr } from '@ami/signalr/feature-signalr';
import { provideApplicationInsights } from '@ami/angular/core/application-insights';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      NgxWebstorageModule.forRoot({ prefix: 'ami_shop', separator: '_', caseSensitive: true })
    ),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation(), withComponentInputBinding()),
    provideHttpClient(withInterceptorsFromDi()),
    provideHttpBaseUrlReplacement({
      aliases: [{
        alias: 'api',
        baseUrl: nxApiBaseUrl,
      }]
    }),
    provideOAuthClient({
      resourceServer: {
        allowedUrls: [`${nxApiBaseUrl}/api`, '@api'],
        sendAccessToken: true,
      },
    }),
    { provide: OAuthStorage, useFactory: localSessionOAuthStorageFactory },
    {
      provide: ErrorHandler,
      useClass: ErrorHandling,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: AuthConfig,
      useValue: {
        issuer: `${nxIdentityIssuerBaseUrl}/`,
        redirectUri: window.location.origin,
        strictDiscoveryDocumentValidation: false,
        clientId: 'shopfloor',
        scope: 'openid profile email shop_floor_workstation offline_access',
        responseType: 'code',
        showDebugInformation: true,
        requireHttps: false
      }
    },
    provideSignalr(nxApiBaseUrl),
    provideApplicationInsights(applicationInsightsInstrumentationKey, appVersion, 'shopFloor'),
    { provide: AMI_SITE_TYPE, useValue: AmiSiteType.ShopFloor },
  ],
};
