import { ChangeDetectionStrategy, Component, Input, computed, effect, inject, signal } from '@angular/core';
import { CORE_UI } from '../core-ui.constant';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorFormatterService } from '@ami/angular/core/error-handling';

@Component({
  selector: 'ami-server-validation-errors',
  standalone: true,
  imports: [CORE_UI],
  templateUrl: './server-validation-errors.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerValidationErrorsComponent {

  private errorFormatterService = inject(ErrorFormatterService);

  private error = signal<HttpErrorResponse | Error | null>(null);

  private validationError = computed(() => {
    const e = this.error() as HttpErrorResponse;
    return e?.status == 400 ? e : null;
  });

  public errorMessage = signal<string>('');

  constructor() {
    effect(async () => {
      const vError = this.validationError();
      this.errorMessage.set(vError
        ? await this.errorFormatterService.formatHttpErrorResponse(vError) as string
        : '');
    }, { allowSignalWrites: true })
  }

  removeTags(errorMessage: string): string {
    return errorMessage.replace( /(<([^>]+)>)/ig, '');
}

  @Input() set model(value:HttpErrorResponse | Error | null) {
    this.error.set(value);
  }
}
