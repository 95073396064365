<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">LOGOUT</h4>
  </div>
  <div class="modal-body">
    <div class="mb-3">
      Logging out will require an Administrator to setup this workstation again
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="dismiss()">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="logout()"
      ngbAutofocus
    >
      <span>Confirm</span>
    </button>
  </div>
</div>
