export function groupByKey<T>(array: Array<any>, key: string): { [key: string]: Array<T> } {
  return array
    .reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
    }, {});
}

export function groupByMultipleKeys(array: Array<any>, f: any) {
  const groups = {} as any;
  array.forEach((o) => {
    const group = f(o).join('/');
      groups[group] = groups[group] || [];
      groups[group].push(o);
  });
  return groups;
}
