import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ami-form-detail-wrapper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-detail-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDetailWrapperComponent {
  @Input() width?: 'small' | 'medium' | 'large' = 'large';
  @Input() useContainer = true;
}
