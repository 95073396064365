import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CORE_UI } from '@ami/angular/core/ui';

@Component({
  selector: 'ami-areas-selection',
  standalone: true,
  imports: [CORE_UI],
  templateUrl: './areas-selection.component.html',
  styleUrls: ['./areas-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AreasSelectionComponent {
  @Input() areas?: Array<any>;
  @Input() selectedName?: string;
  @Output() onChangeSelect = new EventEmitter<any>();

  public onSelect(event: any) {
    const selectedIndex = event?.target?.selectedIndex;
    const selectedOption = event?.target?.options.item(selectedIndex);
    this.onChangeSelect.emit({ id: selectedOption.value, label: selectedOption.label });
  }

  public onSelectArea(id: string, name: string) {
    this.onChangeSelect.emit({ id: id, label: name });
  }

}
