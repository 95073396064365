import * as signalR from '@microsoft/signalr';

export function startHubConnectionWithRetryWithoutWait(connection: signalR.HubConnection) {
  startHubConnectionWithRetry(connection);
}

export async function startHubConnectionWithRetry(
  connection: signalR.HubConnection
) {
  try {
    await connection.start();
    console.log('SignalR Connected.');
  } catch (err) {
    console.log(err);
    await delay(5000);
    await startHubConnectionWithRetry(connection);
  }
}

function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
