<div class="collapse navbar-collapse" id="navbarSupportedContent">
  <ul class="navbar-nav me-auto">
    <li class="nav-item dropdown">
      <a *ngIf="areas?.length !== 1" class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
        aria-expanded="false">
        {{ selectedName }}
      </a>
      <div *ngIf="areas?.length === 1" class="title-page">
        {{ selectedName }}
      </div>
      <ul class="dropdown-menu dropdown-menu-dark areas-list">
        <li *ngFor="let item of areas">
          <a class="dropdown-item" (click)="onSelectArea(item.areaId, item.areaName)">{{ item.areaName }}</a>
        </li>
      </ul>
    </li>
  </ul>
</div>
