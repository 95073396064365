<ami-test-banner [showTestBanner]="showTestBanner"></ami-test-banner>
<nav class="navbar navbar-dark navbar-expand-lg bg-dark" data-bs-theme="dark">
  <div class="container-fluid d-flex justify-content-between" *ngIf="accessToken">
    <div class="flex-grow-1">
      <a class="navbar-brand" routerLink="/a">
        <img src="assets/ami_logo.png" alt="ami logo" />
      </a>
    </div>

    <div class="flex-grow-1 d-flex">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="navbar-nav mx-auto mb-2 mb-lg-0">
          <h1>
            <ami-areas-selection [areas]="getAreas()" [selectedName]="selectedAreaName"
              (onChangeSelect)="onSelectArea($event)"></ami-areas-selection>
          </h1>
        </div>
      </div>
    </div>

    <div class="mb-2 mb-lg-0 flex-grow-1 ms-2 d-flex justify-content-end">
      <button class="btn btn-link" (click)="onOpenModal(content)">
        <h3><i class="bi bi-box-arrow-right"></i></h3>
      </button>
    </div>
  </div>
</nav>
<ng-template #content let-modal>
  <ami-logout (save)="this.logout()" (cancel)="this.onCancelModal()"></ami-logout>
</ng-template>
