import { ToastComponent } from '@ami/angular/core/toasts';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'ami-public-layout',
  standalone: true,
  imports: [CommonModule, RouterModule, MenuComponent, ToastComponent],
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
})
export class PublicLayoutComponent implements OnInit {

  get isAuthenticated() {
    return this.oauthService.getAccessToken()
  }

  constructor(
    private authCodeFlowConfig: AuthConfig,
    private oauthService: OAuthService,
    private router: Router) {
    this.oauthService.configure(this.authCodeFlowConfig);
    this.oauthService.loadDiscoveryDocumentAndLogin();

    // Automatically load user profile
    this.oauthService.events
      .pipe(filter((e) => e.type === 'token_received'))
      .subscribe(async (_) => {
        await this.oauthService.loadUserProfile();

        this.navigateToWorkQueue();
      });
  }

  ngOnInit(): void {
    this.navigateToWorkQueue();
  }

  private navigateToWorkQueue() {
    if (this.isAuthenticated) {
      this.router.navigate(['/a/work-queue']);
    }
  }
}
