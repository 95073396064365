<router-outlet></router-outlet>
<val-default-errors>
  <ng-template valError="required" let-label>{{ label || 'This field' }} is required</ng-template>
  <ng-template valError="notEmpty" let-label>{{ label || 'This field' }} is required</ng-template>
  <ng-template valError="email" let-label>{{ label || 'This field' }} must be a valid email address</ng-template>
  <ng-template valError="min" let-error="error" let-label>
      {{ label || 'This field' }} must be at least {{ error.min | number }}
  </ng-template>
  <ng-template valError="max" let-error="error" let-label>
      {{ label || 'This field' }} must be at most {{ error.max | number }}
  </ng-template>
  <ng-template valError="maxlength" let-error="error" let-label>{{ label || 'This field' }} must be less than or equal to {{ error.requiredLength | number  }}</ng-template>
<!-- same for the other types of error -->
</val-default-errors>
