import { Injectable } from "@angular/core";

export const nxApiBaseUrl: string = process.env['NX_SHOP_API_BASE_URL']!;
export const showTestBanner: boolean = JSON.parse(process.env['NX_APP_SHOW_TEST_BANNER']!);
export const nxIdentityIssuerBaseUrl: string = process.env['NX_IDENTITY_API_BASE_URL']!;
export const applicationInsightsInstrumentationKey: string = process.env['NX_REACT_APP_APP_INSIGHTS_KEY']!;
export const appVersion: string = process.env['NX_REACT_APP_AMI_VERSION']!;
export const amiClaimBase = 'http://schemas.ami.com/';

@Injectable({
  providedIn: 'root'
})
export class AppSettings {
  readonly nxApiBaseUrl = nxApiBaseUrl;
  readonly nxIdentityIssuerBaseUrl = nxIdentityIssuerBaseUrl;
  readonly showTestBanner: boolean = showTestBanner;
  readonly amiClaimBase = amiClaimBase;
}
